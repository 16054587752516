"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AboutUsPage = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
// import colors from "../../../colors.config";
// import baby_and_wellness from "../../assets/baby_and_wellness.png";
// import health_and_beauty from "../../assets/health_and_beauty.png";
// import mobility from "../../assets/mobility.png";
// import oxygen_hire from "../../assets/oxygen_hire.png";
// import call_center from "../../assets/call_center.png";
// import loyalty_card from "../../assets/loyalty_card.png";
var AboutUsPage = function () {
    var _a;
    (0, react_1.useEffect)(function () {
        //
    }, []);
    return (react_1.default.createElement("div", { className: "flex flex-col gap-8" },
        react_1.default.createElement("div", { className: "w-full -z-10" },
            react_1.default.createElement("div", { className: "bg-primary" },
                react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, null,
                    react_1.default.createElement("div", { className: "flex flex-col items-center" },
                        react_1.default.createElement("div", { className: "w-[400px] md:w-[600px] lg:w-[800px] \n                flex flex-col gap-y-4 pt-5" },
                            react_1.default.createElement("div", { className: "py-20 flex flex-col items-center gap-8" },
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "About ".concat((_a = process.env.APP_NAME) !== null && _a !== void 0 ? _a : ""), size: "3xlarge", textColor: "text-white", center: true }),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Welcome to ZLN Energy Solutions, your dynamic partner in the renewable energy revolution! We're on a mission to combat climate change and provide sustainable energy solutions that empower all economic zones.", size: "medium", textColor: "text-white", center: true })))))),
            react_1.default.createElement("div", { className: "-mt-[1px]" },
                react_1.default.createElement("svg", { viewBox: "0 0 20 1.5", xmlns: "http://www.w3.org/2000/svg" },
                    react_1.default.createElement("path", { d: "M 0 0 L 20 0 Q 10 3 0 0", fill: "#1669A3" })))),
        react_1.default.createElement("div", { className: "" },
            react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, { className: "mt-20" },
                react_1.default.createElement("div", { className: "flex flex-col items-center gap-8" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Our Purpose", size: "3xlarge", textColor: "text-secondary", center: true }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "At ZLN Energy Solutions, our purpose is crystal clear: to create a greener, cleaner, and more sustainable future for our planet. We believe in the power of renewable energy to transform the world, and we're committed to making it accessible and affordable for everyone.", size: "medium", textColor: "text-secondary", center: true }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Who We Are", size: "3xlarge", textColor: "text-secondary", center: true }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "We are a team of passionate innovators, dreamers, and change-makers, united by a common vision. Our youthful and energetic spirit drives us to develop cutting-edge technologies that harness the potential of renewable energy sources.", size: "medium", textColor: "text-secondary", center: true }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "What Sets Us Apart", size: "3xlarge", textColor: "text-secondary", center: true }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Innovative Solutions: Our drive for innovation knows no bounds. We continuously explore new possibilities to optimize renewable energy generation and storage, ensuring maximum efficiency and minimal impact on the environment.", size: "medium", textColor: "text-secondary", center: true }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Inclusivity: Sustainability shouldn't be a luxury. We work tirelessly to bridge the gap and provide sustainable energy solutions that cater to diverse economic zones. Together, we can create a world where everyone benefits from clean energy.", size: "medium", textColor: "text-secondary", center: true }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Positive Impact: We measure our success not only by profits but by the positive impact we create. Every project we undertake is a step closer to reducing carbon emissions and safeguarding our planet's future.", size: "medium", textColor: "text-secondary", center: true }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Our Green Portfolio", size: "3xlarge", textColor: "text-secondary", center: true }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "At ZLN Energy Solutions, we take pride in our diverse portfolio of green projects. From solar farms that harness the sun's limitless power to wind energy projects that harness the wind's untapped potential, we're leading the way in sustainable energy solutions.", size: "medium", textColor: "text-secondary", center: true }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Join the Green Movement", size: "3xlarge", textColor: "text-secondary", center: true }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "We firmly believe that a sustainable future is a collective effort. Whether you're a visionary investor, a forward-thinking community, or an individual with a passion for change, you can be a part of our green movement. Together, let's pave the way for a cleaner, brighter tomorrow.", size: "medium", textColor: "text-secondary", center: true }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Together, Let's Power a Sustainable Tomorrow", size: "3xlarge", textColor: "text-secondary", center: true }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Join us at ZLN Energy Solutions and be a part of a brighter, sustainable future. Together, we'll power the change, one renewable energy solution at a time. Let's drive towards a world where clean energy is the norm, and climate change is a thing of the past. Together, we've got the power to make it happen!", size: "medium", textColor: "text-secondary", center: true })))),
        react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, null)));
};
exports.AboutUsPage = AboutUsPage;
// const sampleMissions = [
//   {
//     title: "Our Company",
//     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
//     image: "https://via.placeholder.com/150",
//   },
//   {
//     title: "Our Vision",
//     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
//     image: "https://via.placeholder.com/150",
//   },
//   {
//     title: "What we really do",
//     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
//     image: "https://via.placeholder.com/250",
//   },
// ];
// const sampleDoctors = [
//   {
//     title: "Baby and Wellness Clinic",
//     description: "",
//     image: baby_and_wellness,
//   },
//   {
//     title: "Health and Beauty Clinic",
//     description: "",
//     image: health_and_beauty,
//   },
//   {
//     title: "Mobility Aids & CPAP machines & equipment",
//     description: "",
//     image: mobility,
//   },
//   {
//     title: "Wheelchair Hire & Oxygen Hire",
//     description: "",
//     image: oxygen_hire,
//   },
//   {
//     title: "Chronic Call Centre & Blister Packing",
//     description: "",
//     image: call_center,
//   },
//   {
//     title: "Loyalty Card",
//     description: "",
//     image: loyalty_card,
//   },
// ];

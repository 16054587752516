"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductPage = void 0;
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var get_product_1 = require("../../../api/calls/product/get_product");
var recently_viewed_1 = require("../../../api/calls/product/recently_viewed");
var add_product_thunk_1 = require("../../../reducers/slices/basket/thunks/add_product_thunk");
var selection_1 = require("../../../reducers/slices/selection");
var calculateDiscountPercentage_1 = require("../../../helpers/calculateDiscountPercentage");
var constants_1 = require("./constants");
var ProductPage = function () {
    // const details: IProductPage | undefined = sampleDetails;
    var params = (0, react_router_dom_1.useParams)();
    var productId = params.productId;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.basketState; }), basket = _a.basket, isAddingToBasket = _a.isAddingToBasket;
    var selection = (0, react_redux_1.useSelector)(function (state) { return state.selectionState; }).selection;
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _b = (0, react_1.useState)({
        _id: "",
        sku: "",
        title: "",
        sub_title: "",
        description: "",
        price: "0",
        promotion_price: "0",
        on_promotion: false,
        image_url: "",
        status: "",
        image_list: [],
        rating: {
            average: "0",
            count: "0",
        },
        variations: [],
    }), retrievedProduct = _b[0], setRetrievedProduct = _b[1];
    var _c = (0, react_1.useState)(false), isBuyingProduct = _c[0], setIsBuyingProduct = _c[1];
    var _d = (0, react_1.useState)([]), recentlyViewed = _d[0], setRecentlyViewed = _d[1];
    var _e = (0, react_1.useState)(1), quantity = _e[0], setQuantity = _e[1];
    var _f = (0, react_1.useState)(), selectedProductVariation = _f[0], setSelectedProductVariation = _f[1];
    var formatMoney = doshx_controls_web_1.Utilities.formatMoney;
    var navigate = (0, react_router_dom_1.useNavigate)();
    (0, react_1.useEffect)(function () {
        var _a, _b, _c, _d;
        if ((_b = (_a = selection.selectedProduct) === null || _a === void 0 ? void 0 : _a._id) !== null && _b !== void 0 ? _b : productId) {
            (0, get_product_1.getProduct)({
                _id: (_d = (_c = selection.selectedProduct) === null || _c === void 0 ? void 0 : _c._id) !== null && _d !== void 0 ? _d : productId,
                user_id: user.user._id,
            }).then(function (response) {
                var _a, _b;
                // console.log("Product details fetched", response);
                setRetrievedProduct(response.content);
                // we can define this better as a helper function to set defaults based on color and size
                // set the first variant as the default - for as long as that variant is in stock
                var defaultVariant = (_b = (_a = response.content) === null || _a === void 0 ? void 0 : _a.variations[0]) === null || _b === void 0 ? void 0 : _b.variations.filter(function (v) { return v.status != constants_1.OUT_OF_STOCK; }).at(0);
                // console.log("The variant--response is", defaultVariant);
                if (defaultVariant) {
                    setSelectedProductVariation(__assign(__assign({}, defaultVariant), { type: response.content.variations[0].type }));
                }
                else {
                    setSelectedProductVariation(null);
                }
            });
        }
        else {
            navigate(-1);
        }
    }, [productId]);
    (0, react_1.useEffect)(function () {
        var _a;
        if (user.user._id) {
            (0, recently_viewed_1.getRecentlyViewedProducts)({
                user_id: (_a = user.user._id) !== null && _a !== void 0 ? _a : "",
            }).then(function (response) {
                var _a;
                // console.log("Recent products fetched", response);
                setRecentlyViewed((_a = response.content) !== null && _a !== void 0 ? _a : []);
            });
        }
    }, [user]);
    (0, react_1.useEffect)(function () {
        // buying means a user is ready to checkout the product
        if (isBuyingProduct && !isAddingToBasket) {
            navigate("/basket");
            setIsBuyingProduct(false);
        }
    }, [isBuyingProduct, isAddingToBasket]);
    var generatePriceDisplay = function () {
        var _a;
        if (retrievedProduct.on_promotion) {
            // const discountedPrice = (parseFloat(retrievedProduct.price) * parseFloat(retrievedProduct.promotion_price)) / 100;
            var discountedPercentage = ((_a = ((parseFloat(retrievedProduct.price) - parseFloat(retrievedProduct.promotion_price)) / parseFloat(retrievedProduct.price)) * 100) !== null && _a !== void 0 ? _a : 0).toFixed(0);
            return (react_1.default.createElement("div", { className: "flex flex-row gap-8 items-center" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: formatMoney({
                        value: retrievedProduct.promotion_price,
                        decimalPlaces: 2,
                    }), size: "2xlarge", textColor: "text-primary", bold: true }),
                react_1.default.createElement("div", { className: "line-through" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: formatMoney({
                            value: retrievedProduct.price,
                            decimalPlaces: 2,
                        }), size: "small" })),
                react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-success", borderColor: "border-transparent", addPadding: false },
                    react_1.default.createElement("div", { className: "p-1" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(discountedPercentage, "%"), textColor: "text-white", size: "xsmall" })))));
        }
        else {
            return (react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: formatMoney({
                    value: retrievedProduct.price,
                    decimalPlaces: 2,
                }), size: "xlarge", textColor: "text-primary", bold: true }));
        }
    };
    return (react_1.default.createElement("div", { className: "" },
        react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, { className: "flex flex-col gap-4" },
            react_1.default.createElement("div", { className: "flex flex-col lg:flex-row gap-4" },
                react_1.default.createElement("div", { className: "self-center" },
                    react_1.default.createElement(doshx_controls_web_1.GalleryControl, { images: retrievedProduct.image_list && retrievedProduct.image_list.length > 0
                            ? retrievedProduct.image_list.map(function (item, idx) {
                                return { id: idx.toString(), url: item.url };
                            })
                            : [{ id: "1", url: retrievedProduct.image_url }] })),
                react_1.default.createElement("div", { className: "flex flex-col flex-1 gap-8 lg:self-start" },
                    react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "SKU: ".concat(retrievedProduct.sku), size: "small", textColor: "text-secondary" }),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(retrievedProduct.title), size: "2xlarge", textColor: "text-secondary", bold: true })),
                    generatePriceDisplay(),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: retrievedProduct.sub_title, textColor: "text-secondary" }),
                    react_1.default.createElement("div", { className: "flex flex-row" },
                        retrievedProduct.status == constants_1.OUT_OF_STOCK ? (react_1.default.createElement(doshx_controls_web_1.BoxControl //
                        , { addPadding: true, borderColor: "border-borders-gray" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl //
                            , { label: "OUT OF STOCK", textColor: "text-error" }))) : (react_1.default.createElement(doshx_controls_web_1.IncrementControl, { borderColor: "border-borders-gray", textColor: "text-secondary", iconColor: "text-borders-gray", value: quantity, onChange: function (v) {
                                setQuantity(v);
                            } })),
                        react_1.default.createElement("div", { className: "flex-1" }),
                        retrievedProduct.status != constants_1.OUT_OF_STOCK && (react_1.default.createElement("div", { className: "flex flex-row gap-4" },
                            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Buy", loading: isAddingToBasket, backgroundColor: "bg-transparent" //
                                , textColor: "text-secondary", borderColor: "border-secondary", onClick: function () {
                                    dispatch((0, add_product_thunk_1.add_product_thunk)({
                                        product: retrievedProduct,
                                        quantity: quantity,
                                        selectedVariations: selectedProductVariation ? [selectedProductVariation] : null,
                                    }));
                                    setIsBuyingProduct(true);
                                } }),
                            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Add To Cart", loading: isAddingToBasket, backgroundColor: "bg-secondary", onClick: function () {
                                    dispatch((0, add_product_thunk_1.add_product_thunk)({
                                        product: retrievedProduct,
                                        quantity: quantity,
                                        selectedVariations: selectedProductVariation ? [selectedProductVariation] : null,
                                    }));
                                } })))),
                    retrievedProduct.status != constants_1.OUT_OF_STOCK &&
                        retrievedProduct.variations &&
                        retrievedProduct.variations.map(function (variation, index) {
                            var _a;
                            if (variation.variations && variation.variations.length > 0)
                                return (react_1.default.createElement("div", { className: "flex", key: index },
                                    react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: variation.title, key: index, options: ((_a = variation.variations) !== null && _a !== void 0 ? _a : [])
                                            .filter(function (v) { return v.status != constants_1.OUT_OF_STOCK; })
                                            .map(function (variant, variantKey) {
                                            return {
                                                key: variantKey,
                                                label: variant.title,
                                                value: variant.title, //
                                            };
                                        }), labelColor: "text-primary", textColor: "text-secondary", borderColor: "border-borders-gray", iconColor: "text-borders-gray", size: "small", value: selectedProductVariation === null || selectedProductVariation === void 0 ? void 0 : selectedProductVariation.title, onChange: function (v) {
                                            setSelectedProductVariation(__assign(__assign({}, variation.variations.find(function (variant) { return variant.title == v; })), { type: variation.type }));
                                        } })));
                        }))),
            react_1.default.createElement("div", { className: "flex flex-col lg:flex-row gap-4 py-8" },
                react_1.default.createElement("div", { className: "w-full" },
                    react_1.default.createElement(doshx_controls_web_1.TabControl, { colors: {
                            tabs: {
                                selectedTextColor: "text-secondary",
                                unselectedTextColor: "text-secondary",
                                indicatorColor: "bg-secondary",
                                backgroundColor: "bg-gray-100",
                            },
                        }, tabs: [
                            {
                                title: "Description",
                                content: (react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: retrievedProduct.description, size: "small", textColor: "text-secondary", className: "p-4" })),
                            },
                            // {
                            //   title: "Specification",
                            //   content: (
                            //     <LabelControl
                            //       label={
                            //         "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum"
                            //       }
                            //       size="small"
                            //       textColor="text-secondary"
                            //       className="p-4"
                            //     />
                            //   ),
                            // },
                            // {
                            //   title: "Discussion",
                            //   content: (
                            //     <LabelControl
                            //       label={
                            //         "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum"
                            //       }
                            //       size="small"
                            //       textColor="text-secondary"
                            //       className="p-4"
                            //     />
                            //   ),
                            // },
                            // {
                            //   title: "Reviews",
                            //   content: (
                            //     <LabelControl
                            //       label={
                            //         "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum"
                            //       }
                            //       size="small"
                            //       textColor="text-secondary"
                            //       className="p-4"
                            //     />
                            //   ),
                            // },
                        ] }))),
            recentlyViewed && recentlyViewed.length > 0 && (react_1.default.createElement(doshx_controls_web_1.ProductCarouselSmallControl, { imageFit: "contain", category: {
                    name: "Recently viewed",
                    color: "text-secondary",
                }, products: recentlyViewed.map(function (product, index) {
                    return {
                        _id: product._id,
                        image: product.image_url,
                        title: product.title,
                        amount: product.price,
                        // rating: product.rating.average,
                        borderColor: "border-borders-light",
                        shadowColor: "shadow-shadows-light",
                        textColor: "text-primary",
                        amountColor: product.on_promotion ? "text-disabled" : "text-primary",
                        starActiveColor: "text-borders-light",
                        starInactiveColor: "text-borders-light",
                        loaderColor: "text-primary",
                        discountLabel: (0, calculateDiscountPercentage_1.generateDiscountLabel)(product.on_promotion, product.price, product.promotion_price),
                        discountAmount: product.on_promotion ? product.promotion_price : "",
                        colors: {
                            discount: {
                                // textColor: `text-success`,
                                // iconColor: `text-primary`,
                                amountColor: "text-primary",
                                // backgroundColor: `bg-red-500`,
                            },
                        },
                    };
                }), onClick: function (product) {
                    // console.log("product", product);
                    dispatch((0, selection_1.setSelectedProduct)(product));
                    navigate("/product/".concat(product._id));
                }, button: {
                    // label: "Add to Cart",
                    borderColor: "border-transparent",
                    onClick: function (product) {
                        dispatch((0, add_product_thunk_1.add_product_thunk)({
                            product: product,
                            quantity: quantity,
                            selectedVariations: selectedProductVariation ? [selectedProductVariation] : null,
                        }));
                    },
                } })),
            react_1.default.createElement(doshx_controls_web_1.BoxControl, { borderColor: "border-transparent", className: "py-8" },
                react_1.default.createElement(doshx_controls_web_1.CommitmentsControl, { orientation: "horizontal", commitments: [
                        // { icon: faTruck, title: "Free Delivery", description: "From R40" },
                        { icon: pro_solid_svg_icons_1.faStar, title: "Best Quality", description: "Brand" },
                        // { icon: faHistory, title: "1 Year", description: "For Free Return" },
                        // { icon: faComments, title: "Feedback", description: "99% Real Data" },
                        { icon: pro_solid_svg_icons_1.faCreditCard, title: "Payment", description: "Secure" },
                    ] })))));
};
exports.ProductPage = ProductPage;

"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CategoryProductsPage = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var products_1 = require("../../../api/calls/product/products");
var main_1 = require("../../../reducers/slices/main");
var selection_1 = require("../../../reducers/slices/selection");
var calculateDiscountPercentage_1 = require("../../../helpers/calculateDiscountPercentage");
var CategoryProductsPage = function () {
    var params = (0, react_router_dom_1.useParams)();
    var level = params.level, categoryId = params.categoryId, categoryName = params.categoryName;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    // const { selection } = useSelector<any, ISelectionSliceState>((state) => state.selectionState);
    var _a = (0, react_1.useState)([]), products = _a[0], setProducts = _a[1];
    var _b = (0, react_1.useState)(1), currentPage = _b[0], setCurrentPage = _b[1];
    var _c = (0, react_1.useState)(0), totalRecords = _c[0], setTotalRecords = _c[1];
    var _d = (0, react_1.useState)(10), recordsPerPage = _d[0], setRecordsPerPage = _d[1];
    // useEffect(() => {
    //   // console.log("selection.selectedCategory", selection.selectedCategory);
    // }, [selection.selectedCategory]);
    (0, react_1.useEffect)(function () {
        // let data: any = {
        //   page: currentPage,
        //   size: recordsPerPage,
        // };
        // if ((level ?? 0) == 0) {
        //   data.category_id = categoryId;
        // } else {
        //   data.sub_category_id = categoryId;
        // }
        // getProducts(data).then((response) => {
        (0, products_1.getProducts)({
            category_id: categoryId,
            page: currentPage.toString(),
            size: recordsPerPage.toString(),
        }).then(function (response) {
            if (response.success) {
                // console.log("response.content", response.content);
                var list = response.content.product_list;
                setTotalRecords(parseInt(response.content.count));
                setProducts(list.map(function (product) {
                    return {
                        _id: product._id,
                        title: product.title,
                        image: product.image_url,
                        amount: product.price,
                        // rating: product.rating.average,
                        borderColor: "border-borders-light",
                        shadowColor: "shadow-shadows-light",
                        textColor: "text-primary",
                        amountColor: product.on_promotion ? "text-disabled" : "text-primary",
                        starActiveColor: "text-borders-light",
                        starInactiveColor: "text-borders-light",
                        loaderColor: "text-primary",
                        discountLabel: (0, calculateDiscountPercentage_1.generateDiscountLabel)(product.on_promotion, product.price, product.promotion_price),
                        discountAmount: product.on_promotion ? product.promotion_price : "",
                        colors: {
                            discount: {
                                // textColor: `text-success`,
                                // iconColor: `text-primary`,
                                amountColor: "text-primary",
                                // backgroundColor: `bg-red-500`,
                            },
                        },
                    };
                }));
            }
            else {
                dispatch((0, main_1.setMainModal)({
                    title: "Something went wrong!",
                    messages: [response.message],
                }));
            }
        });
    }, [currentPage, categoryId]);
    return (react_1.default.createElement("div", null,
        react_1.default.createElement(doshx_controls_web_1.ProductsSmallControl, { imageFit: "contain", category: {
                name: decodeURI(categoryName !== null && categoryName !== void 0 ? categoryName : "Unset"),
                color: "text-secondary",
            }, products: products, onClick: function (product) {
                // console.log("product", product);
                dispatch((0, selection_1.setSelectedProduct)(product));
                navigate("/product/".concat(product._id));
            }, pagination: {
                currentPage: currentPage,
                totalRecords: totalRecords,
                recordsPerPage: recordsPerPage,
                onClick: function (pageNumber) {
                    setCurrentPage(pageNumber);
                },
                colors: {
                    selected: {
                        backgroundColor: "bg-primary",
                        textColor: "text-white",
                        borderColor: "border-borders-light",
                    },
                    unselected: {
                        backgroundColor: "bg-secondary",
                        textColor: "text-white",
                        borderColor: "border-borders-light",
                    },
                },
            } })));
};
exports.CategoryProductsPage = CategoryProductsPage;
